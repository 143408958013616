import React, { useContext, useEffect } from 'react'
import { CenteredCard, ErrorBanner } from 'components'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { SnackbarContext, TokensContext } from 'contexts'
import { useHistory } from 'react-router-dom'

const Confirm = () => {
  const tokens = useContext(TokensContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const token = window.location.search.replace(/^.*?token=([^$&]+).*/,'$1')

  const submit = async () => {
    try {
      await tokens.actions.confirm({token: token})
    } catch (e) {
      history.push('/')
      return
    }
    await tokens.actions.verify()
    snackbar.actions.show("Your account has been confirmed")
    history.push('/')
  }

  useEffect(() => {
    submit()
  })

  return (
    <CenteredCard >
      <CardContent>
        {tokens.errors.confirm && <ErrorBanner>{tokens.errors.confirm.title}</ErrorBanner>}
        <Typography type='h5'>Confirming your account...</Typography>
      </CardContent>
    </CenteredCard>
  )
}

export default Confirm
