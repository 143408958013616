import React, {useContext} from 'react'
import {useDependency, useResourceFromPath} from 'hooks'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { AssetsTendersContext } from 'contexts'

const Show = () => {
  const {id} = useResourceFromPath()
  const assetsTenders = useContext(AssetsTendersContext.ReactContext)

  useDependency(() => (
    assetsTenders.actions.show(id, {
      include: ''
    })
  ), [id])

  const assetsTender = assetsTenders.selected

  return (
    <PageContainer>
      <Typography variant='h4'>AssetsTender - {assetsTender.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/assets_tenders/${assetsTender.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Asset</TableCell>
              <TableCell>{String(assetsTender.assetId || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Tender</TableCell>
              <TableCell>{String(assetsTender.tenderId || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

const Component = (props) => (
  <AssetsTendersContext>
    <Show {...props} />
  </AssetsTendersContext>
)
export default Component