import React, { useContext } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import GavelIcon from '@material-ui/icons/Gavel'
import EditIcon from '@material-ui/icons/Edit'
import { errorStringsFromError } from 'utils'
import { SnackbarContext, TendersContext } from 'contexts'
import { usePagination, useDependency } from 'hooks'
import { useHistory } from 'react-router-dom'

const List = () => {
  const tenders = useContext(TendersContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('tenders')

  const [, , reloadTenders] = useDependency(() => (
    tenders.actions.index({
      page: page,
      fields: 'competitionName'
    })
  ), [page])

  const showTender = ({id}) => () => {
    history.push(`/tenders/${id}`)
  }

  const editTender = ({id}) => event => {
    history.push(`/tenders/${id}/edit`)
    event.stopPropagation()
  }

  const deleteTender = ({id}) => event => {
    tenders.actions.destroy({id})
                .then(reloadTenders)
                .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  const renderTenderListItem = (tender) => {
    const { id, competitionName } = tender
    return (
      <ListItem button onClick={showTender(tender)} key={id}>
        <ListItemIcon>
          <GavelIcon />
        </ListItemIcon>
        <ListItemText primary={competitionName}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editTender(tender)}><EditIcon/></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteTender(tender)} entityName="tender" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <ErrorBanner>
        {errorStringsFromError(tenders.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={tenders.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {tenders.list.map(renderTenderListItem )}
      </MuiList>
      <Pagination totalPages={tenders.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='primary' onClick={() => history.push('/tenders/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

const Component = (props) => (
  <TendersContext>
    <List {...props} />
  </TendersContext>
)
export default Component