import React, {useContext} from 'react'
import {Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog} from 'components'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ParticipantUserIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import SvgIcon from '@material-ui/core/SvgIcon'

import { errorStringsFromError } from 'utils'
import {UsersContext, SnackbarContext} from 'contexts'
import {useHistory} from 'react-router-dom'
import {useDependency, usePagination} from 'hooks'

const AdminUserIcon = () => {
  return (
    <SvgIcon>
      <path d="M17 11c.34 0 .67.04 1 .09V6.27L10.5 3 3 6.27v4.91c0 4.54 3.2 8.79 7.5 9.82.55-.13 1.08-.32 1.6-.55-.69-.98-1.1-2.17-1.1-3.45 0-3.31 2.69-6 6-6z"></path>
      <path d="M17 13c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 1.38c.62 0 1.12.51 1.12 1.12s-.51 1.12-1.12 1.12-1.12-.51-1.12-1.12.5-1.12 1.12-1.12zm0 5.37c-.93 0-1.74-.46-2.24-1.17.05-.72 1.51-1.08 2.24-1.08s2.19.36 2.24 1.08c-.5.71-1.31 1.17-2.24 1.17z"></path>
    </SvgIcon>
  )
}

const List = () => {
  const users = useContext(UsersContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('books')

  const [, , reloadUsers] = useDependency(() => (
    users.actions.index({
      page: page,
      fields: {users: 'name,email,role,unconfirmedEmail'}
    })
  ), [page])

  const showUser = ({id}) => () => {
    history.push(`/users/${id}`)
  }

  const editUser = ({id}) => event => {
    history.push(`/users/${id}/edit`)
    event.stopPropagation()
  }

  const deleteUser = ({id}) => event => {
    users.actions.destroy({id})
                .then(reloadUsers)
                .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  const email = (user) => {
    if (user.unconfirmedEmail) {
      return `${user.unconfirmedEmail} (uncomfirmed)`
    }
    return user.email
  }

  const renderUserListItem = (user) => {
    const { name, role, id } = user
    return (
      <ListItem button onClick={showUser(user)} key={id}>
        <ListItemIcon>
        {
          (role === 'admin') ? <AdminUserIcon/> : <ParticipantUserIcon/>
        }
        </ListItemIcon>
        <ListItemText primary={name} secondary={email(user)}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editUser(user)}><EditIcon/></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteUser(user)} entityName="user" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <ErrorBanner>
        {errorStringsFromError(users.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={users.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {users.list.map(renderUserListItem)}
      </MuiList>
      <Pagination totalPages={users.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='primary' onClick={() => history.push('/users/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

const Component = (props) => (
  <UsersContext>
    <List {...props} />
  </UsersContext>
)
export default Component