import React, {useContext} from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import { useFormData, useDependency, useInstanceSaver, useResourceFromPath } from 'hooks'
import {
  ControlledForm,
  ErrorBanner,
  PageContainer,
  PromiseButton,
  DateTimePicker,
  EnumField,
  CurrencyAmountField,
  TimeOfDayPicker,
  DurationField,
  AutocompleteSearch,
} from 'components'
import { TendersContext } from 'contexts'
import {errorStringsFromError} from 'utils'
import { PowerType } from 'models'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const tenders = useContext(TendersContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return tenders.actions.show(
        id, 
        {include: 'gxps'}
      )
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(tenders.selected, { defaults: { gxps: [] }})
  const [save, errors] = useInstanceSaver(formData, tenders, action, { linked: ['gxps'] })
  const { powerType } = formData

  return (
    <PageContainer>
      <Typography variant='h5'>{action === 'edit' ? `Edit Tender - ${formData.competitionName}` : 'Create a new Tender'}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(tenders.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        <TextField fullWidth name='competitionName'/>
        <DateTimePicker fullWidth name='qualificationOpen'/>
        <DateTimePicker fullWidth name='qualificationClose'/>
        <EnumField fullWidth name='powerType' enumName='PowerType'/>
        <EnumField fullWidth name='needType' enumName='NeedType'/>
        <EnumField fullWidth name='needDirection' enumName='NeedDirection'/>
        <EnumField fullWidth name='productType' enumName='ProductType'/>
        <AutocompleteSearch multiple fullWidth name='gxps' searchableField='code' filterName='code' required/>
        <DateTimePicker fullWidth name='competitionOpen'/>
        <DateTimePicker fullWidth name='competitionClose'/>
        <EnumField fullWidth name='competitionType' enumName='CompetitionType'/>
        <CurrencyAmountField fullWidth name='minimumBudget'/>
        <CurrencyAmountField fullWidth name='maximumBudget'/>
        <CurrencyAmountField fullWidth name='availabilityGuidePrice'/>
        <CurrencyAmountField fullWidth name='utilisationGuidePrice'/>
        <CurrencyAmountField fullWidth name='serviceFee'/>
        <DateTimePicker fullWidth name='servicePeriodStart'/>
        <DateTimePicker fullWidth name='servicePeriodEnd'/>
        <TextField fullWidth name='serviceWindowName'/>
        <TimeOfDayPicker fullWidth name='serviceWindowStart'/>
        <TimeOfDayPicker fullWidth name='serviceWindowEnd'/>
        <EnumField multiple fullWidth name='serviceDays' enumName='WeekDay'/>
        <TextField fullWidth name='capacityRequired' 
          type='number'
          InputProps={{
            endAdornment:
              <InputAdornment position="end">{PowerType.powerUnit(powerType)}</InputAdornment>
          }}
        />
        <TextField fullWidth name='minimumAggregateAssetSize' 
          type='number'
          InputProps={{
            endAdornment:
              <InputAdornment position="end">{PowerType.powerUnit(powerType)}</InputAdornment>
          }}
        />
        <DurationField fullWidth name='minimumRunTime'/>
        <DurationField fullWidth name='requiredResponseTime'/>
        <TextField fullWidth name='dispatchEstimateCount' 
          type='number'
        />
        <DurationField fullWidth name='dispatchDuration'/>
        <CurrencyAmountField fullWidth name='fixedAvailabilityPrice'
          InputProps={{
            endAdornment:
              <InputAdornment position="end">{`/${PowerType.powerUnit(powerType)}`}</InputAdornment>
          }}
        />
        <CurrencyAmountField fullWidth name='fixedUtilisationPrice'
          InputProps={{
            endAdornment:
              <InputAdornment position="end">{`/${PowerType.powerUnit(powerType)}`}</InputAdornment>
          }}
        />
        <CurrencyAmountField fullWidth name='fixedServiceFee'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const Component = (props) => (
  <TendersContext>
    <Form {...props} />
  </TendersContext>
)
export default Component