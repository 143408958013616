import APIContext from 'contexts/base/APIContext'

export default class AssetsContext extends APIContext{
  static contextName = 'assets'

  submitForApproval = async ({id}, {...options} = {}) => {
    const { data:updated } = await this.performRequest('createApprovalRequest')({id, options})
    this.setState({selected: updated, list: this.replace(this.state.list, updated)})
    return updated
  }

  approvalApprove = async ({id}, {...options} = {}) => {
    const { data:updated } = await this.performRequest('createApprovalApprovedRequest')({id, options})
    this.setState({selected: updated, list: this.replace(this.state.list, updated)})
    return updated
  }

  approvalReject = async ({id}, {...options} = {}) => {
    const { data:updated } = await this.performRequest('createApprovalRejectedRequest')({id, options})
    this.setState({selected: updated, list: this.replace(this.state.list, updated)})
    return updated
  }
}
