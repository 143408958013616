import { ContextsByType } from 'contexts/base/BaseContext'

class AuthorizationProxy{
  get store(){
    return ContextsByType.tokens[0].state
  }

  get isAuthenticated() {
    return this.userId !== undefined
  }

  get userId(){
    return this.user.id
  }

  get user(){
    return this.store.currentUser || {}
  }

  get admin(){
    return (this.user.role === 'admin')
  }

  get participant(){
    return (this.user.role === 'participant')
  }
}

export const Authorization = new AuthorizationProxy()
