import React, { useContext } from 'react'
import { TokensContext, SnackbarContext } from 'contexts'
import {useHistory} from 'react-router-dom'
import StatusPage from 'containers/status/StatusPage'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const Inactive = () => {
  const tokens = useContext(TokensContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const continueSession = () => {
    tokens.actions.refresh(false).then(() => {
      const location = (
        tokens.savedLocation &&
        !tokens.savedLocation.match(/^\/?(?:tokens)|(?:inactive)\/?/)
      ) ?
        tokens.savedLocation :
        '/'
      history.push(location)
    }).catch(() => {
      tokens.actions.destroy()
      snackbar.actions.show('Session expired')
    })
  }

  return (
    <StatusPage>
      <Typography variant='h6'>User session inactive</Typography>
      <Typography variant='body1'>Your current session has expired.</Typography>
      <Button fullWidth variant='contained' color='secondary' onClick={continueSession}>Continue</Button>
      <br/>
      <br/>
      <Button fullWidth variant='contained' color='primary' onClick={tokens.actions.destroy}>Sign Out</Button>
    </StatusPage>
  )
}

export default Inactive