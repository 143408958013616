import React, {Fragment, useContext, useRef, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import RegionIcon from '@material-ui/icons/MyLocation'
import GxpIcon from '@material-ui/icons/LocationCity'
import DocumentIcon from '@material-ui/icons/Description'
import AssetIcon from '@material-ui/icons/Room'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'
import GavelIcon from '@material-ui/icons/Gavel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Authorization  } from 'utils'
import { makeStyles } from '@material-ui/core/styles'
import { TokensContext, VersionsContext } from 'contexts'

const useStyles = makeStyles({
  title: {
    flex: 1
  },
  drawer: {
    width: 200,
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  }
})

const Nav = (props) => {
  const tokens = useContext(TokensContext.ReactContext)
  const versions = useContext(VersionsContext.ReactContext)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [useMenuOpen, setUserMenuOpen] = useState(false)
  const userMenuButtonRef = useRef()

  useEffect(() => {
    versions.actions.getTag()
  }, [versions.actions])

  const classes = useStyles(props)
  return (
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography className={classes.title} variant="h6" color="inherit">
            <Link to='/'>Flex Services Procurement</Link>
          </Typography>
          <IconButton color="inherit" aria-label="Menu" onClick={() => setDrawerOpen(true)}>
            <MenuIcon/>
          </IconButton>
          <IconButton color="inherit" onClick={() => setUserMenuOpen(true)} ref={userMenuButtonRef}>
            <PersonIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={userMenuButtonRef.current}
        open={useMenuOpen}
        onClose={() => setUserMenuOpen(false)}
      >
        <Link to="/profile"><MenuItem>My Profile</MenuItem></Link>
        <MenuItem onClick={tokens.actions.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{paper: classes.drawer}}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onClick={() => setDrawerOpen(false)}
      >
        {
          Authorization.admin &&
          <Link to='/users'>
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon/>
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Link>
        }
        {
          Authorization.admin &&
          <Link to='/regions'>
            <ListItem button>
              <ListItemIcon>
                <RegionIcon/>
              </ListItemIcon>
              <ListItemText primary="Regions" />
            </ListItem>
          </Link>
        }
        {
          Authorization.admin &&
          <Link to='/gxps'>
            <ListItem button>
              <ListItemIcon>
                <GxpIcon/>
              </ListItemIcon>
              <ListItemText primary="Gxps" />
            </ListItem>
          </Link>
        }
        {
          Authorization.admin &&
          <Link to='/tenders'>
            <ListItem button>
              <ListItemIcon>
                <GavelIcon/>
              </ListItemIcon>
              <ListItemText primary="Tenders" />
            </ListItem>
          </Link>
        }
        <Link to='/assets'>
          <ListItem button>
            <ListItemIcon>
              <AssetIcon/>
            </ListItemIcon>
            <ListItemText primary="Assets" />
          </ListItem>
        </Link>
        {
          Authorization.admin &&
          <Link to='/apidocs'>
            <ListItem button>
              <ListItemIcon>
                <DocumentIcon/>
              </ListItemIcon>
              <ListItemText primary="API Docs" />
            </ListItem>
          </Link>
        }
        { Authorization.admin &&
          <Link to='/audits'>
            <ListItem button>
              <ListItemIcon>
                <ChangeHistoryIcon />
              </ListItemIcon>
              <ListItemText primary="Audits" />
            </ListItem>
          </Link>
        }
        <div className={classes.version}>
          API {versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </Fragment>
  )
}

const Component = (props) => (
  <VersionsContext>
    <Nav {...props} />
  </VersionsContext>
)
export default Component
