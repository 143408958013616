import React from 'react'
import { Switch } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { Authorization  } from 'utils'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/assets_tenders"             component={Containers.AssetsTender.List} requires={Authorization.admin}/>
      <Route exact path="/assets_tenders/new"         component={Containers.AssetsTender.Form} requires={Authorization.admin}/>
      <Route exact path="/assets_tenders/:id"         component={Containers.AssetsTender.Show} requires={Authorization.admin}/>
      <Route exact path="/assets_tenders/:id/edit"    component={Containers.AssetsTender.Form} requires={Authorization.admin}/>
      <Route exact path="/assets"                     component={Containers.Asset.List}/>
      <Route exact path="/assets/new"                 component={Containers.Asset.Form}/>
      <Route exact path="/assets/:id"                 component={Containers.Asset.Show}/>
      <Route exact path="/assets/:id/edit"            component={Containers.Asset.Form}/>
      <Route exact path="/tenders"                    component={Containers.Tender.List}/>
      <Route exact path="/tenders/new"                component={Containers.Tender.Form}/>
      <Route exact path="/tenders/:id"                component={Containers.Tender.Show}/>
      <Route exact path="/tenders/:id/edit"           component={Containers.Tender.Form}/>
      <Route exact path="/gxps"                       component={Containers.Gxp.List}/>
      <Route exact path="/gxps/new"                   component={Containers.Gxp.Form}/>
      <Route exact path="/gxps/:id"                   component={Containers.Gxp.Show}/>
      <Route exact path="/gxps/:id/edit"              component={Containers.Gxp.Form}/>
      <Route exact path="/regions"                    component={Containers.Region.List}/>
      <Route exact path="/regions/new"                component={Containers.Region.Form}/>
      <Route exact path="/regions/:id"                component={Containers.Region.Show}/>
      <Route exact path="/regions/:id/edit"           component={Containers.Region.Form}/>
      <Route exact path="/profile"                    component={Containers.User.Profile}/>
      <Route exact path="/users"                      component={Containers.User.List} requires={Authorization.admin}/>
      <Route exact path="/users/new"                  component={Containers.User.Form}/>
      <Route exact path="/users/:id"                  component={Containers.User.Show}/>
      <Route exact path="/users/:id/edit"             component={Containers.User.Form}/>
      <Route exact path="/audits"                     component={Containers.Audit.List} requires={Authorization.admin}/>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/forms/demo"                 component={Containers.Forms.Demo}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                  component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                   component={Containers.Status.Inactive} />
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} />
      <Route exact path="/"                           component={Containers.Map.Show}/>
      <Route component={Containers.Status.NotFound} />
    </Switch>
  )
}

export default Routes