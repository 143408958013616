import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { useDependency } from 'hooks'
import { Select } from 'components'
import * as API from 'api'

const EnumField = ({ enumName, ...rest }) => {
  const [enumDef, setEnumDef] = useState(null)
  useDependency(async () => {
    const {data: retrievedEnumDef} = await API.Enums.show({name: enumName})
    setEnumDef(retrievedEnumDef)
  }, [])

  return (
    enumDef ? 
      <Select fullWidth {...rest}>
        {
          enumDef.values.map((value) => {
            return <MenuItem key={value} value={value}>{enumDef.descriptions[value]}</MenuItem>
          })
        }
      </Select> :
      <TextField disabled fullWidth value=''/>
  )
}

export default EnumField