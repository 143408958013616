import React, {useContext} from 'react'
import {useDependency, useResourceFromPath} from 'hooks'
import {
  formatLocaleNumeric,
  formatDuration,
  Authorization,
  humanize,
  errorStringsFromError,
} from 'utils'

import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { SnackbarContext, AssetsContext } from 'contexts'

const Show = () => {
  const {id} = useResourceFromPath()
  const assets = useContext(AssetsContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  useDependency(() => (
    assets.actions.show(id, {
      include: 'owner,gxp'
    })
  ), [id])

  const asset = assets.selected
  const { powerUnit, approvalStatus } = asset

  const approveHandler = () => {
    assets.actions.approvalApprove({id})
      .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  const rejectHandler = () => {
    assets.actions.approvalReject({id})
      .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', '))) 
  }

  const submitForApproval = () => {
    assets.actions.submitForApproval({id})
      .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  return (
    <PageContainer>
      <Typography variant='h4'>Asset - {asset.name} ({humanize(approvalStatus)})</Typography>
      <Table>
        <TableBody>
          {
            (approvalStatus === 'not_submitted' || Authorization.admin) &&
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/assets/${asset.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
                {
                  (Authorization.admin && approvalStatus === 'pending') &&
                  <>
                    <IconButton onClick={approveHandler}>
                      <ThumbUpIcon/>
                    </IconButton>
                    <IconButton onClick={rejectHandler}>
                      <ThumbDownIcon/>
                    </IconButton>
                  </>
                }
                {
                  (!Authorization.admin && approvalStatus === 'not_submitted') &&
                  <IconButton onClick={submitForApproval}><CheckCircleOutline/></IconButton>
                }
              </TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell variant='head'>Owner</TableCell>
            <TableCell>{String(asset?.owner?.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Gxp</TableCell>
            <TableCell>{String(asset?.gxp?.code || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Name</TableCell>
            <TableCell>{String(asset.name || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Address</TableCell>
            <TableCell>{String(asset.address || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Power type</TableCell>
            <TableCell>{String(asset.powerTypeDescription || '')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Capacity</TableCell>
            <TableCell>{`${formatLocaleNumeric(asset.capacity)} ${powerUnit}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Maximum run time</TableCell>
            <TableCell>{formatDuration(asset.maximumRunTime)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Minimum response time</TableCell>
            <TableCell>{formatDuration(asset.maximumResponseTime)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Service days</TableCell>
            <TableCell>{(asset.serviceDaysDescription || []).join(', ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant='head'>Approval status</TableCell>
            <TableCell>{humanize(asset.approvalStatus)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

const Component = (props) => (
  <AssetsContext>
    <Show {...props} />
  </AssetsContext>
)
export default Component