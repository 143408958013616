import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'

import {
  formatDuration,
} from 'utils'

const regex = /(?<hours>\d\d) h : (?<minutes>\d\d) m/
const maskedValueFromNumber = (numberValue) => {
  return formatDuration(numberValue)
}

const DurationField = ({value: numberValue, onChange, disabled, ...other}) => {
  const [maskedValue, setMaskedValue] = useState('')
  useEffect(() => {
    setMaskedValue(maskedValueFromNumber(numberValue))
  }, [numberValue])

  const maskedValueChanged = (event) => {
    const maskedValue = event.target.value
    setMaskedValue(maskedValue)

    const match = maskedValue.match(regex)
    if(match) {
      let hours = Number(match.groups.hours)
      let minuts = Number(match.groups.minutes)
      let durationInMinutes = (hours * 60 + minuts)

      onChange({target: { value: durationInMinutes}})
    } else if('__ h : __ m' === maskedValue) {
      onChange({target: { value: null}})
    }
  }
  
  return (
    <InputMask mask='99 h : 99 m'
      value={maskedValue} 
      onChange={maskedValueChanged} 
      disabled={disabled}
      alwaysShowMask
    >
      <TextField {...other} />
    </InputMask>
  )
}

export default DurationField