import React, { Component } from 'react'
import { KeyboardDateTimePicker as DTPicker } from '@material-ui/pickers';

export class DateTimePicker extends Component{

  handleChange = date => {
    this.props.onChange({
      target: {
        value: date ? date.format('YYYY-MM-DD HH:mm:ssZ') : date
      }
    })
  }

  get value(){
    return this.props.value ? new Date(this.props.value) : null
  }

  render = () =>
    <DTPicker
      format="DD/MM/YY HH:mm"
      {...this.props}
      onChange={this.handleChange}
      value={this.value}
    />

}

export default DateTimePicker