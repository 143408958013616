const powerUnit = (powerType) => {
  if(powerType === 'active') {
    return 'MW'
  } else if(powerType === 'reactive') {
    return 'MVar'
  } else {
    return ''
  }
}

const PowerType =  {
  powerUnit
}

export default PowerType