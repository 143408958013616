// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const Tokens = ProtectedJsonAPI.extend(
  '',
  {
    forgot: endpoint => endpoint.method('post').path(() => {
      return '/tokens/forgot'
    }),
    reset: endpoint => endpoint.method('post').path(() => {
      return '/tokens/reset'
    }),
    confirm: endpoint => endpoint.method('post').path(() => {
      return '/tokens/confirm'
    }),
    resendConfirmation: endpoint => endpoint.method('post').path(() => {
      return '/tokens/resend_confirmation'
    }),
    acceptInvite: endpoint => endpoint.method('post').path(() => {
      return '/tokens/accept_invite'
    }),
    update: endpoint => endpoint.method('put').path(() => {
      return '/tokens'
    }),
    destroy: endpoint => endpoint.method('delete').path(() => {
      return '/tokens'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tokens'
    }),
  }
)

export const Enums = ProtectedJsonAPI.extend(
  '',
  {
    show: endpoint => endpoint.path(({name}) => {
      if (name) {
        return `/enums/${name}`
      }
      throw new ApiPathError('show', {name})
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/users'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/users'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const AssetsTenders = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/assets_tenders'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/assets_tenders'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/assets_tenders/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/assets_tenders/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/assets_tenders/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Assets = ProtectedJsonAPI.extend(
  '',
  {
    createApprovalRequest: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/assets/${id}/approval_requests`
      }
      throw new ApiPathError('create_approval_request', {id})
    }),
    createApprovalApprovedRequest: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/assets/${id}/approval_approved_requests`
      }
      throw new ApiPathError('create_approval_approved_request', {id})
    }),
    createApprovalRejectedRequest: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/assets/${id}/approval_rejected_requests`
      }
      throw new ApiPathError('create_approval_rejected_request', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/assets'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/assets'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/assets/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/assets/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/assets/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Tenders = ProtectedJsonAPI.extend(
  '',
  {
    createAssetSubmission: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/tenders/${id}/asset_submissions`
      }
      throw new ApiPathError('create_asset_submission', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/tenders'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tenders'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/tenders/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/tenders/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/tenders/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Gxps = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/gxps'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/gxps'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/gxps/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/gxps/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/gxps/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Regions = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/regions'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/regions'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/regions/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/regions/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/regions/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Audits = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/audits'
    }),
  }
)
