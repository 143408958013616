import React, {useContext} from 'react'
import {useDependency, useResourceFromPath} from 'hooks'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { GxpsContext } from 'contexts'

const Show = () => {
  const {id} = useResourceFromPath()
  const gxps = useContext(GxpsContext.ReactContext)

  useDependency(() => (
    gxps.actions.show(id, {
      include: 'region'
    })
  ), [id])

  const gxp = gxps.selected

  return (
    <PageContainer>
      <Typography variant='h4'>Gxp - {gxp.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/gxps/${gxp.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Region</TableCell>
              <TableCell>{String(gxp.region?.name || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(gxp.name || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Code</TableCell>
              <TableCell>{String(gxp.code || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Longitude</TableCell>
              <TableCell>{String(gxp.longitude || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Latitude</TableCell>
              <TableCell>{String(gxp.latitude || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

const Component = (props) => (
  <GxpsContext>
    <Show {...props} />
  </GxpsContext>
)
export default Component