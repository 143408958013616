import React, { useContext, useRef, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'

import { TendersContext, SnackbarContext } from 'contexts'
import { useDependency } from 'hooks'

import L from 'leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import { flatten, humanize, Authorization, errorStringsFromError } from 'utils'
import TenderDetails from './TenderDetails'
import SubmittedAssets from './SubmittedAssets'
import QualifyingAssets from './QualifyingAssets'

const geojsonMarkerOptions = {  
  radius: 6,
  color: "#FFF",
  weight: 2,
  opacity: 1,
  fillOpacity: 0.8,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
  },
  map: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: '50%',
    },
  },
  tenders: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: '50%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '28rem',
    },
    overflowY: 'scroll',
    padding: 8,
  },
  tenderTitle: {
    color: '#fff', 
    textAlign: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '1rem',
  },
  cluster: {
    width: "15px !important",
    height: "15px !important",
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 11,
    borderRadius: 15,
    overflow: 'hidden',
    border: '2px solid white',
    boxSizing: 'content-box',
    color: '#FFF',
  },
}))

const colorForTenderFeature = ({properties: {tender: {capacityRequired}}}) => {
  const range = 220
  const rangeOffset = 70
  const maxRange = 100000
  const pos = parseInt(rangeOffset + ((Math.min(capacityRequired, maxRange) / maxRange) * range), 10)
  return `hsl(${pos}, 50%, 55%)`
}

const Show = (props) => {  
  const classes = useStyles(props)
  const tenders = useContext(TendersContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)
  
  const mapContainerRef = useRef(null)
  const leafletMapRef = useRef(null)
  const MarkersGroupRef = useRef(null)
  const GXPGeoJSONRef = useRef(null)

  const [selectedTender, setSelectedTender] = useState(null)
  
  useDependency(() => {
    tenders.actions.index({
      page: 1, 
      pageSize: 100,
      include: 'gxps',
      filter: { upcoming: true }
    })
    
  }, [])
  
  const resetHighlight = (GXPGeoJSON) => e => {
    if(GXPGeoJSON){
      GXPGeoJSON.resetStyle(e.layer)
    }
  }

  const highlightFeature = e => {
    e.layer.setStyle({
        weight: 3,
        color: 'white',
        dashArray: '',
        fillOpacity: 0.7
    })
  }

  useEffect(() => {
    // Initialize the leaflet map.
    leafletMapRef.current = L.map(mapContainerRef.current, {
      maxBounds: L.latLngBounds(
        L.latLng(-48.28664, 165.77557),
        L.latLng(-32.28664, 181.77557)
      ),
      minZoom: 5,
      scrollWheelZoom: true
    }).setView([-41.28664, 174.77557], 6)

    const CartoDB_Positron = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 19,
    })
    CartoDB_Positron.addTo(leafletMapRef.current)
    leafletMapRef.current.fitBounds(L.latLngBounds(
      L.latLng(-48.28664, 165.77557),
      L.latLng(-32.28664, 181.77557)
    ))

    return () => {
      // Remove the map.
      leafletMapRef.current.remove()
      leafletMapRef.current = null
    }
  }, [])

  useEffect(() => {
    if(tenders.list.length === 0) {
      return
    }
    
    MarkersGroupRef.current = L.markerClusterGroup({
      maxClusterRadius: 40,
      iconCreateFunction: cluster => {
        const clusterToolTip = cluster.getAllChildMarkers().map(({feature: { properties: { gxpCode }}}) => gxpCode).join(',')
        cluster.bindTooltip(clusterToolTip, {direction: "top"});
        return L.divIcon({
          className: classes.cluster,
          html: '<div style="background-color: rgb(178, 198, 83);">' + cluster.getChildCount() + '</div>'
        });
      }
    })

    const tenderGeoJSON = {
      features: flatten(
        tenders.list.map((tender) => {
          const { competitionName, gxps } = tender
          return gxps.map(({code, longitude, latitude}) => {
            return {
              geometry: {
                coordinates: [longitude, latitude],
                type: "Point"
              },
              properties: {
                tender,
                gxpCode: code,
                toolTip: `${competitionName}(${code})`,
              },
              type: "Feature"
            }
          })
        })
      )
    }
    
    GXPGeoJSONRef.current = L.geoJSON(tenderGeoJSON, {
      "weight": 1,
      style: feature => {
        return {
          weight: 2,
          opacity: 1,
          fillOpacity: 0.7,
          color: '#EFEFEF',
          fillColor: colorForTenderFeature(feature)
        }
      },
      "pointToLayer": function (feature, latlng) {
        return L.circleMarker(latlng, geojsonMarkerOptions)
      },
      onEachFeature: (feature, layer) => {
        const { properties: { toolTip }} = feature
        layer.bindTooltip(toolTip, {
          offset: L.point(0, -5),
          direction: 'top'
        })
        layer.on({
          click: (e) => {
            const { feature: { properties: { tender }}} = e.target
            setSelectedTender(tender)
          }
        })
      }
    })

    MarkersGroupRef.current.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight(GXPGeoJSONRef.current),
    })
    MarkersGroupRef.current.addLayer(GXPGeoJSONRef.current)
    leafletMapRef.current.addLayer(MarkersGroupRef.current)
    

    return () => {
      if(!!leafletMapRef.current && !!MarkersGroupRef.current) {
        leafletMapRef.current.removeLayer(MarkersGroupRef.current)
        MarkersGroupRef.current = null
        GXPGeoJSONRef.current = null
      }
    }
  }, [tenders.list, classes.cluster])

  const submitHandler = async (selectedAssets, message) => {
    const { id } = selectedTender
    const updatedTender = await tenders.actions.submitAssets({
      id, 
      assetId: selectedAssets.map(({id}) => id),
      message
    }, 
    { 
      include: 'gxps'
    }).catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
    setSelectedTender(updatedTender)
  }

  const renderSelectedTender = () => {
    if(!selectedTender) {
      return null
    }

    const { 
      competitionName,
      productType,
    } = selectedTender

    return (
      <>
        <div className={classes.tenderTitle}>
          <Typography variant='h4'>{`${competitionName} (${humanize(productType)})`}</Typography>
        </div>
        <TenderDetails tender={selectedTender}/>
        {
          Authorization.isAuthenticated &&
          <SubmittedAssets tender={selectedTender}/>
        }
        {
          Authorization.participant &&
          <QualifyingAssets tender={selectedTender} onSubmit={submitHandler}/>
        }
      </>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.map} ref={mapContainerRef}>
        <Typography>This will display the map</Typography>
      </div>
      <div className={classes.tenders}>
        { renderSelectedTender() }
      </div>
    </div>
  )
}

const Component = (props) => (
  <TendersContext>
    <Show {...props} />
  </TendersContext>
)
export default Component