import React, { useContext, useState } from 'react'
import {useDependency, useFilter, usePagination} from 'hooks'
import { AuditsContext } from 'contexts'
import TextField from '@material-ui/core/TextField/TextField'
import Typography from '@material-ui/core/Typography'
import { humanize, userFriendlyDate } from 'utils'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import {
  Pagination,
  SearchCombo,
  MultiSelect,
  LinkButton,
  ConfirmationDialog,
  PageContainer
} from 'components'

const humanizeValue = (value) => {
  if (value && isNaN(value) && moment(value).isValid()) {
    return userFriendlyDate(value)
  }
  else {
    return value
  }
}

const getChangeSet = (audit) => (
  Object.entries(audit?.changeset || {}).map(([field, [from, to]]) => ({field, from, to}))
)

const List = () => {
  const audits = useContext(AuditsContext.ReactContext)

  const [page, setPage] = usePagination('audits')
  const [filter, setFilter] = useFilter(setPage, 'audits')

  useDependency(() => (
    audits.actions.index({
      page,
      filter
    })
  ), [page, filter])

  const [showAudit, setShowAudit] = useState(null)

  const everyEvent = ['create', 'update', 'delete']

  return (
    <PageContainer>
      <Typography variant='h4'>Audit Logs</Typography>
      <br />
      <Pagination
        totalPages={audits.totalPages}
        page={page}
        onPageSelected={setPage}
        startAdornment={
          <SearchCombo onFilterChange={setFilter} filter={filter} searchTextMember={'freeText'}>
            <div>
              <TextField label='Entity' name='item_type' fullWidth={false} />
              <TextField label='ID' name='item_id' fullWidth={false} />
            </div>
            <div>
              <DatePicker clearable label='Start Time' name='createdAtDateAfter' />
              <DatePicker clearable label='End Time' name='createdAtDateBefore' />
            </div>
            <div>
              <Typography variant="subtitle1">Events</Typography>
              <MultiSelect name="event" options={everyEvent} defaultOptions={everyEvent} unsetWhenDefault />
            </div>
          </SearchCombo>
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell>Entity</TableCell>
            <TableCell>Id</TableCell>
            <TableCell>Create Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {audits.list.map(audit => (
            <TableRow key={audit.id}>
              <TableCell>{humanize(audit.event)}</TableCell>
              <TableCell >{audit.itemType}</TableCell>
              <TableCell >
                <LinkButton onClick={() => setShowAudit(audit)} disabled={!getChangeSet(audit).length}>
                  {audit.itemId}
                </LinkButton>
              </TableCell>
              <TableCell>{userFriendlyDate(audit.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog title="Changes" onConfirm={() => setShowAudit(null)} open={!!showAudit}
        canCancel={false} maxWidth={'lg'}
      >
        <Table breakpoint={400}>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Old Value</TableCell>
              <TableCell>New Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getChangeSet(showAudit).map(change =>
              <TableRow key={change.field}>
                <TableCell >{humanize(change.field)}</TableCell>
                <TableCell >{humanizeValue(change.from)}</TableCell>
                <TableCell >{humanizeValue(change.to)}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ConfirmationDialog>
    </PageContainer>
  )
}

const Component = (props) => (
  <AuditsContext>
    <List {...props} />
  </AuditsContext>
)
export default Component
