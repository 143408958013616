import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {ControlledForm, CenteredCard, ErrorBanner, PromiseButton} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { TokensContext, SnackbarContext } from 'contexts'
import {useFormData} from 'hooks'

const Forgot = () => {
  const tokens = useContext(TokensContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const [formData, setFormData] = useFormData()

  const submit = async () => {
    await tokens.actions.forgot(formData)
    snackbar.actions.show("Request Sent")
  }

  return (
    <CenteredCard>
      <ControlledForm data={formData} onChange={setFormData} onSubmit={submit}>
        <CardContent>
          <Typography variant='h6'>Forgot your password?</Typography>
          {tokens.error && <ErrorBanner>{tokens.error.title}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth name='email' autoFocus/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton variant='contained' color='secondary' fullWidth type='submit'>Submit</PromiseButton>
        </CardActions>
        <Link className='link-small' to='/tokens/sign_in'>Sign in</Link>
      </ControlledForm>
    </CenteredCard>
  )
}

export default Forgot