import React, {useContext} from 'react'
import { useFormData, useDependency, useInstanceSaver, useResourceFromPath } from 'hooks'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { AssetsTendersContext } from 'contexts'
import {errorStringsFromError} from 'utils'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const assetsTenders = useContext(AssetsTendersContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return assetsTenders.actions.show(id)
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(assetsTenders.selected)
  const [save, errors] = useInstanceSaver(formData, assetsTenders, action)

  return (
    <PageContainer>
      <Typography variant='h5'>{action === 'edit' ? `Edit AssetsTender - ${formData.name}` : 'Create a new AssetsTender'}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(assetsTenders.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        <TextField fullWidth name='assetId'/>
        <TextField fullWidth name='tenderId'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const Component = (props) => (
  <AssetsTendersContext>
    <Form {...props} />
  </AssetsTendersContext>
)
export default Component