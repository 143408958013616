import moment from 'moment'

export function userFriendlyDate(date){
  return moment(date).format('MMM Do YY, h:mm:ss a')
}

export function roundDateOrMoment(date, nearestMins){
  const duration = moment.duration(nearestMins, 'minute')
  return moment(Math.floor((+date) / (+duration)) * (+duration))
}

export function formatLocaleDate(date, {includeYear=true, emptyIfNotValid=false}={}){
  const momentValue = moment(date)
  if(!momentValue.isValid() && emptyIfNotValid) {
    return ''
  }
  return momentValue.format(`DD/MM${includeYear ? '/YYYY' : ''}`)
}

export function formatLocaleDateTime(date, {includeYear=true, includeSeconds=false, emptyIfNotValid=false}={}){
  const momentValue = moment(date)
  if(!momentValue.isValid() && emptyIfNotValid) {
    return ''
  }
  return momentValue.format(`DD/MM${includeYear ? '/YYYY' : ''} HH:mm${includeSeconds ? ':ss' : ''}`)
}