import React, { useContext } from 'react'
import { Pagination, 
         FABFixed, 
         ErrorBanner, 
         PageContainer, 
         DeleteConfirmationDialog,
         SearchCombo,
         LabeledSelect,
       } from 'components'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import GxpIcon from '@material-ui/icons/LocationCity'
import EditIcon from '@material-ui/icons/Edit'
import { errorStringsFromError } from 'utils'
import { SnackbarContext, GxpsContext } from 'contexts'
import { usePagination, useFilter, useDependency } from 'hooks'
import { useHistory } from 'react-router-dom'

const List = () => {
  const gxps = useContext(GxpsContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('gxps')
  const [filter, setFilter] = useFilter(setPage, 'gxps')

  const [, , reloadGxps] = useDependency(() => (
    gxps.actions.index({
      page: page,
      filter,
      sort: `${filter.sortDir === "desc" ? "-" : ""}${filter.sortBy || "code"}`,
      fields: {gxps: 'name,code'}
    })
  ), [page, filter])

  const showGxp = ({id}) => () => {
    history.push(`/gxps/${id}`)
  }

  const editGxp = ({id}) => event => {
    history.push(`/gxps/${id}/edit`)
    event.stopPropagation()
  }

  const deleteGxp = ({id}) => event => {
    gxps.actions.destroy({id})
                .then(reloadGxps)
                .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  const renderGxpListItem = (gxp) => {
    const { id, name, code } = gxp
    return (
      <ListItem button onClick={showGxp(gxp)} key={id}>
        <ListItemIcon>
          <GxpIcon />
        </ListItemIcon>
        <ListItemText primary={code} secondary={name}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editGxp(gxp)}><EditIcon/></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteGxp(gxp)} entityName="gxp" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <ErrorBanner>
        {errorStringsFromError(gxps.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination 
        totalPages={gxps.totalPages} 
        page={page} 
        onPageSelected={setPage}
        startAdornment={
          <SearchCombo searchTextMember='code' onFilterChange={setFilter} filter={filter}>
            <TextField name="code" fullWidth />
            <LabeledSelect name="sortBy"  label="Sort By" style={{width: "50%"}} options={{"code": "Code", "name" : "Name"}} />
            <LabeledSelect name="sortDir" label="Sort Direction" style={{width: "50%"}} options={{"asc": "Ascending", "desc": "Descending"}} />
          </SearchCombo>
        }
      />
      <MuiList dense>
        {gxps.list.map(renderGxpListItem )}
      </MuiList>
      <Pagination totalPages={gxps.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='primary' onClick={() => history.push('/gxps/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

const Component = (props) => (
  <GxpsContext>
    <List {...props} />
  </GxpsContext>
)
export default Component