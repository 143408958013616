import React, { useContext } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, DeleteConfirmationDialog } from 'components'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import { errorStringsFromError } from 'utils'
import { SnackbarContext, AssetsTendersContext } from 'contexts'
import { usePagination, useDependency } from 'hooks'
import { useHistory } from 'react-router-dom'

const List = () => {
  const assetsTenders = useContext(AssetsTendersContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('assetsTenders')

  const [, , reloadAssetsTenders] = useDependency(() => (
    assetsTenders.actions.index({
      page: page,
      fields: {assetsTenders: 'name'}
    })
  ), [page])

  const showAssetsTender = ({id}) => () => {
    history.push(`/assets_tenders/${id}`)
  }

  const editAssetsTender = ({id}) => event => {
    history.push(`/assets_tenders/${id}/edit`)
    event.stopPropagation()
  }

  const deleteAssetsTender = ({id}) => event => {
    assetsTenders.actions.destroy({id})
                .then(reloadAssetsTenders)
                .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }

  const renderAssetsTenderListItem = (assetsTender) => {
    const { id, name } = assetsTender
    return (
      <ListItem button onClick={showAssetsTender(assetsTender)} key={id}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary={name}/>
        <ListItemSecondaryAction>
          <IconButton onClick={editAssetsTender(assetsTender)}><EditIcon/></IconButton>
          <DeleteConfirmationDialog onConfirm={deleteAssetsTender(assetsTender)} entityName="assetsTender" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <ErrorBanner>
        {errorStringsFromError(assetsTenders.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination totalPages={assetsTenders.totalPages} page={page} onPageSelected={setPage}/>
      <MuiList dense>
        {assetsTenders.list.map(renderAssetsTenderListItem )}
      </MuiList>
      <Pagination totalPages={assetsTenders.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='primary' onClick={() => history.push('/assets_tenders/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

const Component = (props) => (
  <AssetsTendersContext>
    <List {...props} />
  </AssetsTendersContext>
)
export default Component