import React, {Component} from 'react'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'

export class PromiseButton extends Component {

  state = {loading: false, success: false, error: false}

  componentDidMount = () => {
    this._mounted = true
  }

  componentWillUnmount = () => {
    this._mounted = false
  }

  showStatus = (status) => {
    if (this._mounted) {
      this.setState({[status]: true})
      setTimeout(() => {
        if (this._mounted)
          this.setState({[status]: false})
      }, 3000)
    }
  }

  handleClick = async(event) => {
    this.setState({loading: true}, this.transition)
    try {
      await this.props.onClick(event)
      this.showStatus('success')
    }
    catch(_ex) {
      this.showStatus('error')
    }
    finally {
      if (this._mounted)
        this.setState({loading: false})
    }
  }

  render = () => {
    const {loading, success, error} = this.state
    const {classes, className, ...props} = this.props
    return (
      <Button {...props} onClick={this.handleClick} className={classes(className, 'root', {loading, success, error})} ref={ref => this.buttonRef = ref} />
    )
  }

}

const styles = ({palette}) => ({
  root: {
    transition: 'backgroundColor 500ms'
  },
  loading: {
    backgroundColor: '#999',
    pointerEvents: 'none',
  },
  success: {
    backgroundColor: `${palette.success.main} !important`,
  },
  error: {
    backgroundColor: `${palette.error.main} !important`,
  }
})

export default withStyles(styles, {name: 'PromiseButton'})(PromiseButton)