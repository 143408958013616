import React, {useContext} from 'react'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import FaceIcon from '@material-ui/icons/Face'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {PageContainer} from 'components'
import {UsersContext} from 'contexts'
import {Authorization} from 'utils'
import {useDependency} from 'hooks'
import {useHistory} from 'react-router-dom'

const Profile = () => {
  const users = useContext(UsersContext.ReactContext)

  const history = useHistory()

  useDependency(() => {
    return users.actions.show(Authorization.user.id)
  }, [Authorization.user.id])

  const currentUser = users.selected

  const email = currentUser.unconfirmedEmail ? `${currentUser.unconfirmedEmail} (uncomfirmed)` : currentUser.email

  return (
    <PageContainer>
      <Typography variant='h6'>Profile</Typography>
      <List>
        <ListItem>
          <ListItemSecondaryAction>
            <IconButton onClick={() => history.push(`/users/${currentUser.id}/edit`)}>
              <EditIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon><PersonIcon/></ListItemIcon>
          <ListItemText primary={currentUser.name} secondary='name'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><EmailIcon/></ListItemIcon>
          <ListItemText primary={email} secondary='email'/>
        </ListItem>
        <ListItem>
          <ListItemIcon><FaceIcon/></ListItemIcon>
          <ListItemText primary={currentUser.role} secondary='role'/>
        </ListItem>
      </List>
    </PageContainer>
  )
}

const Component = (props) => (
  <UsersContext>
    <Profile {...props} />
  </UsersContext>
)
export default Component