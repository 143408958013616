import React, { Component } from 'react'
import { KeyboardDatePicker as DPicker } from '@material-ui/pickers';

export class DatePicker extends Component{

  handleChange = date => {
    this.props.onChange({
      target: {
        value: date ? date.format('YYYY-MM-DD') : date
      }
    })
  }

  get value(){
    return this.props.value ? new Date(this.props.value) : null
  }

  render = () =>
    <DPicker
      format="DD/MM/YYYY"
      {...this.props}
      onChange={this.handleChange}
      value={this.value}
    />

}

export default DatePicker