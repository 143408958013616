import React, {useContext} from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import { useFormData, useDependency, useInstanceSaver, useResourceFromPath } from 'hooks'
import {
  ControlledForm,
  ErrorBanner,
  PageContainer,
  PromiseButton,
  AutocompleteSearch,
  DurationField,
  EnumField,
} from 'components'
import { AssetsContext } from 'contexts'
import {
  errorStringsFromError,
  Authorization,
  humanize,
} from 'utils'
import { PowerType } from 'models'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const assets = useContext(AssetsContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return assets.actions.show(id, {include: 'owner,gxp'})
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(assets.selected)
  const [save, errors] = useInstanceSaver(formData, assets, action, { linked: ['gxp', 'owner'] })
  const { powerType, approvalStatus } = formData

  return (
    <PageContainer>
      <Typography variant='h5'>{action === 'edit' ? `Edit Asset - ${formData.name} (${humanize(approvalStatus)})` : 'Create a new Asset'}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(assets.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        {
          Authorization.admin &&
          <AutocompleteSearch fullWidth 
            name='owner' 
            subject='user' 
            searchableField='name'
            filterName='participant'
          />
        }
        <AutocompleteSearch fullWidth name='gxp' searchableField='code' filterName='code' required/>
        <TextField fullWidth name='name'/>
        <TextField fullWidth name='address' required/>
        <EnumField fullWidth name='powerType' enumName='PowerType' required/>
        <TextField fullWidth name='capacity'
          type='number'
          InputProps={{
            endAdornment:
              <InputAdornment position="end">{PowerType.powerUnit(powerType)}</InputAdornment>
          }}
        />
        <DurationField fullWidth name='maximumRunTime'/>
        <DurationField fullWidth name='maximumResponseTime'/>
        <EnumField multiple fullWidth name='serviceDays' enumName='WeekDay'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const Component = (props) => (
  <AssetsContext>
    <Form {...props} />
  </AssetsContext>
)
export default Component