import React from 'react'
import StatusPage from 'containers/status/StatusPage'
import {useHistory} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const NotFound = () => {
  const history = useHistory()

  return (
    <StatusPage>
      <Typography variant='h5'>Uh-oh!</Typography>
      <Typography variant='body1'> We couldn't find what you are looking for.</Typography>
      <br/>
      <Button fullWidth variant='contained' color='secondary' onClick={history.goBack}>Go Back!</Button>
    </StatusPage>
  )
}

export default (NotFound)