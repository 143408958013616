import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import moment from 'moment'
import { humanize } from 'utils'

import Card from './Card'

const useStyles = makeStyles((theme) => ({
  competitionDates: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
  },
  competitionDatesTile: {
    textAlign: 'center',
    flexGrow: 1,
    padding: '0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: '#edf5f8',
    '&:not(:last-child)': {
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1rem',
      },
      [theme.breakpoints.up('sm')]: {
        marginRight: '1rem',
      },
    },
  },
  tenderDetails: {
    padding: 0,
  },
  fontBig: {
    fontSize: 24,
    fontWeight: 500,
  },
  tenderField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dotsString: {
    flexGrow: 1, 
    marginLeft: '0.5rem', 
    marginRight: '0.5rem',
    borderBottomStyle: 'dotted',
    borderBottomWidth: 1,
    lineHeight: 0,
    height: 0,
    alignSelf: 'center',
  }
}))

const TenderDetails = (props) => {
  const theme = useTheme()
  const wideScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const { tender } = props
  const { 
    competitionOpen,
    competitionClose,
    gxps,
  } = tender

  const fields = [
    { field: 'qualificationClose', renderer: (value) => moment(value).format('DD MMM YYYY HH:mm')},
    { field: 'powerType' },
    { field: 'needType' },
    { field: 'productType' },
    { field: 'needDirection' },
    { field: 'competitionType' },
  ]
  const openDate = moment(competitionOpen).format('DD MMM YYYY')
  const openTime = moment(competitionOpen).format('HH:mm')
  const closeDate = moment(competitionClose).format('DD MMM YYYY')
  const closeTime = moment(competitionClose).format('HH:mm')

  const classes = useStyles(props)

  const Field = ({label, value}) => {
    return (
      <li className={classes.tenderField}>
        <Typography>{label}</Typography>
        <span className={classes.dotsString}></span>
        <Typography>{value}</Typography>
      </li>
    )
  }

  const fieldRenderer = ({field, label, renderer = humanize}) => {
    const value = tender[field]
    return <Field key={field} label={label || humanize(field)} value={renderer(value)}/>
  }

  return (
    <Card>
      <div className={classes.competitionDates}>
        <div className={classes.competitionDatesTile}>
          {
            wideScreen ?
            <>
              <Typography className={classes.fontBig}>{openDate}</Typography>
              <Typography className={classes.fontBig}>{openTime}</Typography>
            </> : <Typography className={classes.fontBig}>{`${openDate} ${openTime}`}</Typography>
          }
          <Typography>Competition open</Typography>
        </div>
        <div className={classes.competitionDatesTile}>
          {
            wideScreen ?
            <>
              <Typography className={classes.fontBig}>{closeDate}</Typography>
              <Typography className={classes.fontBig}>{closeTime}</Typography>
            </> : <Typography className={classes.fontBig}>{`${closeDate} ${closeTime}`}</Typography>
          }
          <Typography>Competition close</Typography>
        </div>
      </div>
      <ul className={classes.tenderDetails}>
        {
          fields.map(fieldRenderer)
        }
        {
          gxps.map(({id, code}) => <Field key={`gxp_${id}`} label='GXP' value={code}/>)
        }
      </ul>
    </Card>
  )
}

export default TenderDetails