import React, {useContext} from 'react'
import {ErrorBanner, ControlledForm, PageContainer, Select} from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import {errorStringsFromError} from 'utils'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Authorization } from 'utils'
import { UsersContext } from 'contexts'
import Box from '@material-ui/core/Box'
import {useDependency, useFormData, useInstanceSaver, useResourceFromPath} from 'hooks'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const users = useContext(UsersContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return users.actions.show(id)
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(users.selected)
  const [save, errors] = useInstanceSaver(formData, users, action)

  return (
    <PageContainer>
      <Typography variant='h5'>Edit User - {formData.name}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(users.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        <TextField fullWidth name='name'/>
        <TextField fullWidth name='email'/>
        <Select disabled={action === 'edit' && !Authorization.admin} fullWidth name='role'>
          <MenuItem value='participant'>Participant</MenuItem>
          <MenuItem value='admin'>Admin</MenuItem>
        </Select>
        {
          (action === 'edit') &&
          <FormControlLabel control={
            <ControlledForm onChange={setFormData} data={formData}>
              <Checkbox name='skipInvite'/>
            </ControlledForm>} label="Skip Invite"
          />
        }
        <br/>
        {
          ((action === 'edit' && Authorization.user.id === users.selected.id) || formData.skipInvite) &&
          <Box p={2} bgcolor="background.default">
            <Typography variant='body1'>Password {action === 'edit' && '(Optional)'}</Typography>
            {
              Authorization.user.id === users.selected.id &&
              <TextField type="password" fullWidth name="oldPassword"/>
            }
            <TextField type='password' fullWidth name='password'/>
            <TextField disabled={!formData.password} type='password' fullWidth name='passwordConfirmation'/>
          </Box>
        }

        <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
      </ControlledForm>
    </PageContainer>
  )
}

const Component = (props) => (
  <UsersContext>
    <Form {...props} />
  </UsersContext>
)
export default Component