import {
  Tokens as TokensBase,
  Assets as AssetsBase,
  Tenders as TendersBase,
} from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'
import { ApiPathError } from './generated'

export const Tokens = TokensBase.extend(
  '',
  {
    refresh: endpoint => endpoint.method('put').path('/tokens').paramsHandler(() => {}).headers((headers, auth) => {return {...headers, ...(auth ? {Authorization: auth, "X-Authorization": auth} : {}) }}),
  }
)

export const Enums = ProtectedJsonAPI.extend(
  '',
  {
    show: endpoint => endpoint.path(({name}) => {
      if (name) {
        return `/enums/${name}`
      }
      throw new ApiPathError('show', {name})
    }),    
  }
)

export const Assets = AssetsBase.extend(
  '',
  {
    createApprovalRequest: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/assets/${id}/approval_requests`
      }
      throw new ApiPathError('createApprovalRequest', {id})
    }),
    createApprovalApprovedRequest: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/assets/${id}/approval_approved_requests`
      }
      throw new ApiPathError('createApprovalApprovedRequest', {id})
    }),
    createApprovalRejectedRequest: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/assets/${id}/approval_rejected_requests`
      }
      throw new ApiPathError('createApprovalRejectedRequest', {id})
    }),
  }
)

export const Tenders = TendersBase.extend(
  '',
  {
    createAssetSubmission: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/tenders/${id}/asset_submissions`
      }
      throw new ApiPathError('createAssetSubmission', {id})
    }),
  }
)

export * from './generated'