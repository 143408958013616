import React, {useContext} from 'react'
import { useFormData, useDependency, useInstanceSaver, useResourceFromPath } from 'hooks'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton, AutocompleteSearch} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { GxpsContext } from 'contexts'
import {errorStringsFromError} from 'utils'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const gxps = useContext(GxpsContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return gxps.actions.show(id, {
        include: 'region'
      })
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(gxps.selected)
  const [save, errors] = useInstanceSaver(formData, gxps, action, { linked: ['region'] })

  return (
    <PageContainer>
      <Typography variant='h5'>{action === 'edit' ? `Edit Gxp - ${formData.name}` : 'Create a new Gxp'}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(gxps.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        <TextField fullWidth name='name'/>
        <TextField fullWidth name='code'/>
        <TextField fullWidth name='longitude' type='numeric'/>
        <TextField fullWidth name='latitude' type='numeric'/>
        <AutocompleteSearch fullWidth name='region' />
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const Component = (props) => (
  <GxpsContext>
    <Form {...props} />
  </GxpsContext>
)
export default Component