import React from 'react'
import moment from 'moment'

import { TimePicker } from 'components'

const TimeOfDayPicker = ({ value: stringValue, onChange, ...rest }) => {
  const momentValue = stringValue ? moment(stringValue, 'HH:mm') : null

  const onTimePickerChanged = ({ target: { value: momentValue }}) => {
    const stringValue = momentValue ? moment(momentValue).format('HH:mm') : null

    onChange({
      target: {
        value: stringValue
      }
    })
  }

  return (
    <TimePicker value={momentValue} onChange={onTimePickerChanged} {...rest} />
  )
}

export default TimeOfDayPicker