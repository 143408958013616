import React from 'react'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'

function NumberFormatCurrency(props) {
  const { currency, decimalScale, inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix={currency}
      decimalScale={decimalScale}
    />
  )
}

const CurrencyAmountField = ({ currency = '$', decimalScale = 0, InputProps, ...other}) => {

  return (
    <TextField {...other}
      InputProps={{
        inputComponent: NumberFormatCurrency,
        inputProps: {currency, decimalScale},
        ...InputProps
      }}
    />
  )
}

export default CurrencyAmountField