import React, {useContext} from 'react'
import {ControlledForm, CenteredCard, ErrorBanner, PromiseButton} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { TokensContext } from 'contexts'
import {useFormData} from 'hooks'
import {useHistory} from 'react-router-dom'

const AcceptInvitation = () => {
  const tokens = useContext(TokensContext.ReactContext)

  const [fromData, setFormData] = useFormData()
  const history = useHistory()

  const token = window.location.search.replace(/^.*?token=([^&$]+).*$/,'$1')

  const submit = async () => {
    await tokens.actions.acceptInvite({...fromData, token})
    await tokens.actions.verify()
    history.push('/')
  }

  const errors = tokens.errors.acceptInvite?.meta

  return (
    <CenteredCard>
      <ControlledForm data={fromData} errors={errors} onChange={setFormData} onSubmit={submit}>
        <CardContent>
          <Typography variant='h6'>Accept your invitation</Typography>
          {tokens.errors.acceptInvite && <ErrorBanner>{tokens.errors.acceptInvite.title}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth name='password' type='password' autoFocus/>
            <TextField fullWidth name='passwordConfirmation' type='password' label='Password'/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton variant='contained' color='secondary' fullWidth type='submit'>Submit</PromiseButton>
        </CardActions>
      </ControlledForm>
    </CenteredCard>
  )
}

export default AcceptInvitation