import React, { useContext } from 'react'
import useFormData from 'hooks/useFormData'
import { Link } from 'react-router-dom'
import {ControlledForm, CenteredCard, ErrorBanner, PromiseButton} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { TokensContext, SnackbarContext } from 'contexts'
import {useHistory} from 'react-router-dom'

const SignIn = () => {
  const tokens = useContext(TokensContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [formData, setFormData] = useFormData({})

  const signIn = async () => {
    try{
      await tokens.actions.create(formData)
      const location = (tokens.savedLocation && !tokens.savedLocation.match(/^\/?tokens\//)) ?
        tokens.savedLocation :
        '/'
      history.push(location)
      snackbar.actions.show('Signed in successfully')
    }catch(err){
      snackbar.actions.show('Sign in failed')
    }
  }

  const errors = tokens.errors.create?.meta

  return (
    <CenteredCard>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={signIn}>
        <CardContent>
          <Typography variant='h6'>Sign in</Typography>
          {tokens.errors.create && <ErrorBanner>{tokens.errors.create.title}</ErrorBanner>}
          <FormGroup>
            <TextField name='email'   type='text'/>
            <TextField name='password'type='password'/>
              <FormControlLabel
                control={
                  <ControlledForm data={formData} onChange={setFormData}>
                  <Checkbox name="rememberMe" value="checkedA"/>
                  </ControlledForm>
                }
                label="Remember Me"
              />
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton fullWidth variant='contained' color='primary' type='submit'>Submit</PromiseButton>
        </CardActions>
        <Link className='link-small' to='/tokens/forgot'>Forgot password</Link>
      </ControlledForm>
    </CenteredCard>
  )
}

export default SignIn