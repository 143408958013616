import APIContext from 'contexts/base/APIContext'

export default class TendersContext extends APIContext{
  static contextName = 'tenders'

  submitAssets = async (item, {...options} = {}) => {
    const { data:updated } = await this.performRequest('createAssetSubmission')({...item, options})
    this.setState({selected: updated, list: this.replace(this.state.list, updated)})
    return updated
  }  
}
