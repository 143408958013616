import React, {useContext} from 'react'
import { useFormData, useDependency, useInstanceSaver, useResourceFromPath } from 'hooks'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { RegionsContext } from 'contexts'
import {errorStringsFromError} from 'utils'

const Form = () => {
  const {id, action} = useResourceFromPath()
  const regions = useContext(RegionsContext.ReactContext)

  useDependency(() => {
    if(action === 'edit'){
      return regions.actions.show(id)
    }
  }, [id, action])

  const [formData, setFormData] = useFormData(regions.selected)
  const [save, errors] = useInstanceSaver(formData, regions, action)

  return (
    <PageContainer>
      <Typography variant='h5'>{action === 'edit' ? `Edit Region - ${formData.name}` : 'Create a new Region'}</Typography>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={save}>
        <ErrorBanner>{errorStringsFromError(regions.actions.apiErrorsFor('create', 'update'))}</ErrorBanner>
        <TextField fullWidth name='name'/>
      <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
      </ControlledForm>
    </PageContainer>
  )
}

const Component = (props) => (
  <RegionsContext>
    <Form {...props} />
  </RegionsContext>
)
export default Component