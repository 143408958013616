import React, { useContext } from 'react'
import { 
  Pagination, 
  PageContainer, 
  FABFixed, ErrorBanner, 
  DeleteConfirmationDialog,
  SearchCombo,
  LabeledSelect,
} from 'components'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import AssetIcon from '@material-ui/icons/Room'
import EditIcon from '@material-ui/icons/Edit'
import { errorStringsFromError, humanize, Authorization } from 'utils'
import { SnackbarContext, AssetsContext } from 'contexts'
import { usePagination, useDependency, useFilter } from 'hooks'
import { useHistory } from 'react-router-dom'

const List = () => {
  const assets = useContext(AssetsContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [page, setPage] = usePagination('assets')
  const [filter, setFilter] = useFilter(setPage, 'assets')

  const [, , reloadAssets] = useDependency(() => (
    assets.actions.index({
      page: page,
      filter,
      fields: {assets: 'name,approvalStatus'}
    })
  ), [page, filter])

  const showAsset = ({id}) => () => {
    history.push(`/assets/${id}`)
  }

  const editAsset = ({id}) => event => {
    history.push(`/assets/${id}/edit`)
    event.stopPropagation()
  }

  const deleteAsset = ({id}) => event => {
    assets.actions.destroy({id})
                .then(reloadAssets)
                .catch(error => snackbar.actions.show(errorStringsFromError(error).join(', ')))
  }  

  const renderAssetListItem = (asset) => {
    const { id, name, approvalStatus } = asset
    return (
      <ListItem button onClick={showAsset(asset)} key={id}>
        <ListItemIcon>
          <AssetIcon />
        </ListItemIcon>
        <ListItemText primary={name} secondary={humanize(approvalStatus)}/>
        <ListItemSecondaryAction>
          {
            (approvalStatus === 'not_submitted' || Authorization.admin) &&
            <IconButton onClick={editAsset(asset)}><EditIcon/></IconButton>
          }
          <DeleteConfirmationDialog onConfirm={deleteAsset(asset)} entityName="asset" />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <PageContainer>
      <ErrorBanner>
        {errorStringsFromError(assets.actions.apiErrorsFor('index', 'destroy'))}
      </ErrorBanner>
      <Pagination 
        totalPages={assets.totalPages} 
        page={page} 
        onPageSelected={setPage}
        startAdornment={
          <SearchCombo searchTextMember='name' onFilterChange={setFilter} filter={filter}>
            <LabeledSelect name="approvalStatus" label="Approval status" 
              options={{not_submitted: "Not submitted", pending: "Pending", approved: 'Approved'}}
              fullWidth
            />
          </SearchCombo>
        }
      />
      <MuiList dense>
        {
          assets.list.map(renderAssetListItem )
        }
      </MuiList>
      <Pagination totalPages={assets.totalPages} page={page} onPageSelected={setPage}/>
      <FABFixed color='primary' onClick={() => history.push('/assets/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
  )
}

const Component = (props) => (
  <AssetsContext>
    <List {...props} />
  </AssetsContext>
)
export default Component