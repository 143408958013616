import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { isProduction } from 'utils'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      {
        !isProduction() &&
        <Route exact path="/apidocs"                  component={Containers.Docs.ApiDocs} />
      }
      <Route exact path="/"                           component={Containers.Map.Show}/>
      <Route component={() =>  <Redirect to={"/tokens/sign_in"}/>}/>
    </Switch>
  )
}

export default Routes
