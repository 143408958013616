import {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {SnackbarContext, TokensContext} from 'contexts'
import {Authorization, deepSet} from 'utils'

const useInstanceSaver = (
  formData, apiContext, action, {
    nested = [], linked = [], saveAction, afterSave, redirect, params = {}, mapFormData
  } = {}
) => {
  const history = useHistory()
  const snackbar = useContext(SnackbarContext.ReactContext)
  const tokens = useContext(TokensContext.ReactContext)

  const createMode = action === 'new'
  const editMode = !createMode

  const attributesToSave = {...formData, relationships: {}}
  linked.forEach(attribute => {
    const relationship = attributesToSave[attribute]
    delete attributesToSave[attribute]
    if(relationship){
      if(Array.isArray(relationship)){
        attributesToSave.relationships[attribute] = {data: relationship.map(({type, id}) => ({ type, id }))}
      }else{
        attributesToSave.relationships[attribute] = {data: {type: relationship.type, id: relationship.id}}
      }
    }
  })
  nested.forEach(attribute => {
    const relationship = attributesToSave[attribute]
    delete attributesToSave[attribute]
    if(relationship)
      attributesToSave.relationships[attribute] = {data: relationship}
  })

  const actions = apiContext.actions
  const errors = apiContext.errors
  const error = errors[editMode ? 'update' : 'create']
  const formErrors = Object.entries(error?.meta || {}).reduce((acc, [name, value]) => (
    deepSet(value.join(', '), name, acc)
  ), {})

  const save = async () => {
    try{
      if(!(saveAction && saveAction instanceof Promise)){
        let mappedAttributesToSave = (typeof mapFormData === 'function') ? mapFormData(attributesToSave) : attributesToSave
        saveAction = editMode ?
          actions.update(mappedAttributesToSave, params) :
          actions.create(mappedAttributesToSave, params)
      }
      const result = await saveAction

      if(typeof afterSave === 'function') {
        afterSave(result)
      }

      if (redirect !== false) {
        let redirectTo = null
        if(redirect) {
          redirectTo = (typeof redirect === 'function') ? redirect(result) : redirectTo = redirect
        }

        if(redirectTo) {
          history.push(redirectTo)
        } else {
          if(window.location.pathname !== Authorization.store.savedLocation){
            history.goBack()
          }else{
            history.push('/')
            tokens.actions.clearSavedWindowLocation()
          }
        }
      }

      snackbar && snackbar.actions.show(`Saved ${attributesToSave.name ? `"${attributesToSave.name}"` : '' }`)

      return result
    }catch(err){
      snackbar && snackbar.actions.show(`Error saving: ${attributesToSave.name ? `"${attributesToSave.name}"` : '' }`)
      console.error('Error saving. Rethrowing err: ', err)
      throw err
    }
  }

  return [save, formErrors]
}

export default useInstanceSaver
