import React, { useEffect } from 'react'
import 'swagger-ui/dist/swagger-ui.css'
import { TokenStore } from 'services'

const ApiDocs = () => {
  useEffect(() => {
    const url = "/api/docs"
    let swaggerUi
    import('swagger-ui').then(({default: SwaggerUI}) => {
      swaggerUi = SwaggerUI({
        dom_id: '#api-docs',
        url,
        requestInterceptor: (req) => {
          if (req.url === url){
            req.headers.Authorization = global.localStorage.auth;
            req.headers['X-Authorization'] = global.localStorage.auth;
          }
          return req;
        },
        deepLinking: true,
        sorter: "alpha",
        jsonEditor: true,
        defaultModelRendering: 'schema',
        showRequestHeaders: true,
        presets: [
          SwaggerUI.presets.apis,
          SwaggerUI.presets.base,
          SwaggerUI.presets.lowlight
        ],
        plugins: [
          SwaggerUI.plugins.DownloadUrl
        ],
        onComplete: () => {
          swaggerUi.preauthorizeApiKey('ApiKey', TokenStore.auth)
        }
      })
    })
  }, [])

  return (
    <div id='api-docs' style={{background: 'white'}} />
  )
}

export default ApiDocs