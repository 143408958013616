import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

import { useDependency } from 'hooks'
import { AssetsTendersContext } from 'contexts'
import Card from './Card'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1rem'
  },
  list: {
    padding: 0,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1rem',
  },
  itemTitle: {
    fontWeight: 'bold',
  },
  itemField: {

  }
}))

const SubmittedAssets = (props) => {
  const classes = useStyles(props)
  const { tender } = props

  const assetsTenders = useContext(AssetsTendersContext.ReactContext)

  useDependency(() => {
    assetsTenders.actions.index({
      page: 1, 
      pageSize: 100,
      filter: { 
        forTender: tender?.id,
      },
      include: 'asset'
    })
  }, [tender])

  const renderAssetTender = ({id, asset: {id: assetId, name, address}, message}) => {
    return (
      <li key={id} className={classes.item}>
        <Link to={`/assets/${assetId}`}>
          <Typography className={classes.itemTitle} component='span'>{name}</Typography>
        </Link>
        <Typography className={classes.itemField} component='span'>Address: {address}</Typography>
        <Typography className={classes.itemField} component='span'>Message: {message}</Typography>
      </li>
    )
  }

  return (
    <Card classes={{root: classes.root}}>
      <Typography><strong>Submitted assets</strong></Typography>
      <ul className={classes.list}>
      {
        assetsTenders.list.map(renderAssetTender)
      }
      </ul>
    </Card>
  )
}

const Component = (props) => (
  <AssetsTendersContext>
    <SubmittedAssets {...props} />
  </AssetsTendersContext>
)
export default Component