import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { useDependency } from 'hooks'
import { AssetsContext } from 'contexts'
import Card from './Card'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1rem'
  }
}))

const QualifyingAssets = (props) => {
  const classes = useStyles(props)
  const { tender, onSubmit } = props
  const assets = useContext(AssetsContext.ReactContext)
  const [checkedAssets, setCheckedAssets] = useState({})
  const [message, setMessage] = useState('')

  const selectionEmpty = !(Object.values(checkedAssets).some((v) => !!v))

  useEffect(() => {
    setCheckedAssets({})
    setMessage('')
  }, [tender])

  useDependency(() => {
    assets.actions.index({
      page: 1, 
      pageSize: 100,
      filter: { 
        qualifyingForTender: tender?.id,
        excludeSubmitted: tender?.id,
      }
    })
    
  }, [tender])

  const assetCheckedHandler = (asset) => ({target: { checked }}) => {
    const { id } = asset
    setCheckedAssets({
      ...checkedAssets,
      [id]: (checked)
    })
  }

  const messageChangedHandler = ({target: { value }}) => {
    setMessage(value)
  }

  const submitHandler = (e) => {
    const selectedAssets = assets.list.filter(({id}) => !!checkedAssets[id])
    onSubmit(selectedAssets, message)
  }

  const renderAsset = (asset) => {
    const {id, name, address} = asset
    return (
      <ListItem key={id}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={!!checkedAssets[id]}
            tabIndex={-1}
            disableRipple
            onChange={assetCheckedHandler(asset)}
            color='primary'
          />
        </ListItemIcon>
        <ListItemText primary={name} secondary={address}/>
      </ListItem>
    )
  }

  return (
    <Card classes={{root: classes.root}}>
      <Typography><strong>Qualifying assets</strong></Typography>
      <MuiList>
      {
        assets.list.map(renderAsset)
      }
      </MuiList>
      {
        (!selectionEmpty) &&
        <>
          <TextField label='Message' value={message} onChange={messageChangedHandler} fullWidth/>
          <Button fullWidth variant='contained' color='primary' onClick={submitHandler}>Submit</Button>
        </>
      }
    </Card>
  )
}

const Component = (props) => (
  <AssetsContext>
    <QualifyingAssets {...props} />
  </AssetsContext>
)
export default Component