import React, {useEffect, Fragment, useContext, useRef} from 'react'
import { SessionStatus } from '../../constants'
import * as Containers from 'containers'
import { throttle } from 'utils'
import { Routes, Nav } from '.'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarContext, TokensContext } from 'contexts'
import {makeStyles} from '@material-ui/core/styles'

const activityEventTypes = ['mousemove', 'click', 'keydown', 'resize']

const useStyles = makeStyles({
  contentWrapper: {
    flex: '1 0 auto',
    flexShrink: 1,
    overflow: 'hidden',
    display: 'flex',
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      //IE ONLY
      minHeight: 'calc(100vh - 130px)'
    },
    '& > div': {
      flex: '1 0 auto',
      maxWidth: '100%'
    }
  }
})

const CurrentRoutes = ({loginState}) => {
  switch (loginState) {
    case SessionStatus.UNKNOWN:         { return <Routes.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Routes.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Routes.Unauthenticated/> }
    default: { return false }
  }
}

const CurrentNav = ({loginState}) => {
  switch (loginState) {
    case SessionStatus.UNKNOWN:         { return <Nav.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Nav.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Nav.Unauthenticated/> }
    default: { return false }
  }
}

const App = (props) => {
  const tokens = useContext(TokensContext.ReactContext)

  useEffect(() => {
    tokens.actions.registerActivity()
    tokens.actions.verify(true)
  }, [tokens.actions])

  const containerRef = useRef()
  const registerActivity = throttle(() => {
    tokens.actions.registerActivity()
  })
  useEffect(() => {
    const container = containerRef.current
    activityEventTypes.forEach(type => container.addEventListener(type, registerActivity))
    return () => {
      activityEventTypes.forEach(type => container.removeEventListener(type, registerActivity))
    }
  })

  const classes = useStyles(props)
  return (
    <Fragment>
      <CssBaseline />
      <CurrentNav loginState={tokens.loginState} />
      <div className={classes.contentWrapper} ref={containerRef}>
        <CurrentRoutes loginState={tokens.loginState} />
      </div>
      <Containers.Shared.Snackbar/>
    </Fragment>
  )
}

const Component = (props) => (
  <TokensContext>
    <SnackbarContext>
      <App {...props} />
    </SnackbarContext>
  </TokensContext>
)
export default Component