import React, {useContext} from 'react'
import {useDependency, useResourceFromPath} from 'hooks'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

import { PageContainer } from 'components'
import { TendersContext } from 'contexts'
import {
  formatLocaleDateTime,
  formatLocaleNumeric,
  formatDuration,
} from 'utils'

const formatCurrencyAmount = (amount) => {
  return formatLocaleNumeric(amount, {prefix: '$'})
}

const Show = () => {
  const {id} = useResourceFromPath()
  const tenders = useContext(TendersContext.ReactContext)

  useDependency(() => (
    tenders.actions.show(id, {
      include: 'gxps'
    })
  ), [id])

  const tender = tenders.selected
  const { powerUnit } = tender

  return (
    <PageContainer>
      <Typography variant='h4'>Tender - {tender.competitionName}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/tenders/${tender.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Competition name</TableCell>
              <TableCell>{String(tender.competitionName || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Qualification open</TableCell>
              <TableCell>{formatLocaleDateTime(tender.qualificationOpen, {emptyIfNotValid: true})}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Qualification close</TableCell>
              <TableCell>{formatLocaleDateTime(tender.qualificationClose, {emptyIfNotValid: true})}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Power type</TableCell>
              <TableCell>{String(tender.powerTypeDescription || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Need type</TableCell>
              <TableCell>{String(tender.needTypeDescription || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Need direction</TableCell>
              <TableCell>{String(tender.needDirectionDescription || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Product type</TableCell>
              <TableCell>{String(tender.productTypeDescription || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>GXPs</TableCell>
              <TableCell>{(tender.gxps || []).map(({code}) => code).join(', ')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Competition open</TableCell>
              <TableCell>{formatLocaleDateTime(tender.competitionOpen, {emptyIfNotValid: true})}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Competition close</TableCell>
              <TableCell>{formatLocaleDateTime(tender.competitionClose, {emptyIfNotValid: true})}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Competition type</TableCell>
              <TableCell>{String(tender.competitionTypeDescription || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Minimum budget</TableCell>
              <TableCell>{String(tender.minimumBudget || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Maximum budget</TableCell>
              <TableCell>{formatCurrencyAmount(tender.maximumBudget)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Availability guide price</TableCell>
              <TableCell>{formatCurrencyAmount(tender.availabilityGuidePrice)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Utilisation guide price</TableCell>
              <TableCell>{formatCurrencyAmount(tender.utilisationGuidePrice)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Service fee</TableCell>
              <TableCell>{formatCurrencyAmount(tender.serviceFee)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Service period start</TableCell>
              <TableCell>{String(tender.servicePeriodStart || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Service period end</TableCell>
              <TableCell>{String(tender.servicePeriodEnd || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Service window name</TableCell>
              <TableCell>{String(tender.serviceWindowName || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Service window start</TableCell>
              <TableCell>{String(tender.serviceWindowStart || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Service window end</TableCell>
              <TableCell>{String(tender.serviceWindowEnd || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Service days</TableCell>
              <TableCell>{(tender.serviceDaysDescription || []).join(', ')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Capacity required</TableCell>
              <TableCell>{`${formatLocaleNumeric(tender.capacityRequired)} ${powerUnit}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Minimum aggregate asset size</TableCell>
              <TableCell>{`${formatLocaleNumeric(tender.minimumAggregateAssetSize)} ${powerUnit}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Minimum run time</TableCell>
              <TableCell>{formatDuration(tender.minimumRunTime)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Required response time</TableCell>
              <TableCell>{formatDuration(tender.requiredResponseTime)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Dispatch estimate count</TableCell>
              <TableCell>{String(tender.dispatchEstimateCount || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Dispatch duration</TableCell>
              <TableCell>{formatDuration(tender.dispatchDuration)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Fixed availability price</TableCell>
              <TableCell>{`${formatCurrencyAmount(tender.fixedAvailabilityPrice)} / ${powerUnit}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Fixed utilisation price</TableCell>
              <TableCell>{`${formatCurrencyAmount(tender.fixedUtilisationPrice)} / ${powerUnit}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Fixed service fee</TableCell>
              <TableCell>{formatCurrencyAmount(tender.fixedServiceFee)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
  )
}

const Component = (props) => (
  <TendersContext>
    <Show {...props} />
  </TendersContext>
)
export default Component