import React, { useContext } from 'react'
import {ControlledForm, CenteredCard, ErrorBanner, PromiseButton} from 'components'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import { SnackbarContext, TokensContext } from 'contexts'
import {useFormData} from 'hooks'
import {useHistory} from 'react-router-dom'

const ResendConfirmation = () => {
  const tokens = useContext(TokensContext.ReactContext)
  const snackbar = useContext(SnackbarContext.ReactContext)

  const history = useHistory()

  const [formData, setFormData] = useFormData()

  const submit = async () => {
    await tokens.actions.resendConfirmation(formData)
    snackbar.actions.show("Request Sent")
    history.push('/confirmation_resent')
  }

  return (
    <CenteredCard>
      <ControlledForm data={formData} onChange={setFormData} onSubmit={submit}>
        <CardContent>
          <Typography variant='h6'>Didn't receive your confirmation email?</Typography>
          <Typography variant='body2'>Enter your email address to resend the confirmation email</Typography>
          {tokens.errors.resendConfirmation && (
            <p>
              <ErrorBanner>
                {tokens.errors.resendConfirmation?.title}
              </ErrorBanner>
            </p>
          )}
          <TextField name='email' fullWidth/>
        </CardContent>
        <CardActions>
          <PromiseButton fullWidth variant='contained' color="primary" type='submit'>Submit</PromiseButton>
        </CardActions>
      </ControlledForm>
    </CenteredCard>
  )
}

export default ResendConfirmation