import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import PersonIcon from '@material-ui/icons/Person'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  title: {
    flex: 1
  },
})

const Nav = (props) => {
  const history = useHistory()
  const classes = useStyles(props)
  const location = useLocation()
  
  const displayLoginButton = (location.pathname !== '/tokens/sign_in')

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography className={classes.title} variant="h6" color="inherit">
          <Link to='/'>Flex Services Procurement</Link>
        </Typography>
        {
          displayLoginButton &&
          <Tooltip title="Log in">
            <IconButton color="inherit" onClick={() => history.push('/tokens/sign_in')}>
              <PersonIcon/>
            </IconButton>
          </Tooltip>
        }
      </Toolbar>
    </AppBar>
  )
}

export default Nav