import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: ({classes}) => ({
    borderRadius: '0.5rem',
    padding: '1rem',
    backgroundColor: '#dfecf1',
    fontSize: 16,
    ...classes?.root
  }),
}))

const Card = (props) => {
  const classes = useStyles(props)
  const { children } = props
  
  return (
    <div className={classes.root}>
      { children }
    </div>
  )
}

export default Card